window.fbAsyncInit = function() {
  FB.init({
    appId      : fbAppId,
    version    : 'v2.8',
    xfbml      : true,
    status     : true,
    cookie     : true,
    oauth      : true
  });

  if (window.canvas) FB.Canvas.setSize({ width: 520, height: 1600});
};

// FacebookSDK
(function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s);
  js.id = id;
  js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.8";
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk')); // Replace 'facebook-jssdk' with your page id.

// Compatibility with Rails Turbo
(function($) {
  var fbRoot;

  function saveFacebookRoot() {
    if ($('#fb-root').length) {
      fbRoot = $('#fb-root').detach();
    }
  };

  function restoreFacebookRoot() {
    if (fbRoot != null) {
      if ($('#fb-root').length) {
        $('#fb-root').replaceWith(fbRoot);
      } else {
        $('body').append(fbRoot);
      }
    }

    if (typeof FB !== "undefined" && FB !== null) { // Instance of FacebookSDK
      FB.XFBML.parse();
    }
  };

  document.addEventListener('turbo:request-start', saveFacebookRoot)
  document.addEventListener('turbo:load', restoreFacebookRoot)
}(jQuery));
